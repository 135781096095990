import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ImageModalPage } from '../../image-modal/image-modal.page';
import { SharedService } from 'src/app/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-booking-details',
  templateUrl: './user-booking-details.page.html',
  styleUrls: ['./user-booking-details.page.scss'],
})
export class UserBookingDetailsPage implements OnInit {
	bookingId;
  	booking: any = {};
  	showLoader = true;
  	currencyCode: any;
  	taxType: any;
    APP_PROJECT_ID = environment.firebase.projectId;
    showQrCode = false;

  constructor(private bookingService: BookingService,
    private modalController: ModalController,
    private configService: ConfigService,
    private sharedService: SharedService) { }

  async ngOnInit() {
    this.booking = {id: this.bookingId, ...await this.bookingService.getBookingData(this.bookingId) as {}};
    this.showLoader = false;
    this.currencyCode = this.configService.environment.currencyCode;
	  this.taxType = this.configService.environment.taxType;

    if (this.APP_PROJECT_ID == "bwi-1085") { // ! [bwi-1085] belongs to partyNights
      this.showQrCode = true;
    } else {
      this.showQrCode = false;
    }

  }

  getImageUrl() {
    const imgType = 'thumb';
    if(this.booking.item.coverPic && this.booking.item.coverPic[imgType]) {
      return this.booking.item.coverPic[imgType];
    }
    if(this.booking.item.coverPic && !this.booking.item.coverPic[imgType] && this.booking.item.coverPic.url) {
      return this.booking.item.coverPic.url;
    }
    if(!this.booking.item.coverPic || (!this.booking.item.coverPic[imgType] && !this.booking.item.coverPic.url)) {
      return "assets/img/placeholder-img.jpg";
    }
  }

  imgZoom(img: any) {
    this.modalController.create({
      component: ImageModalPage,
      cssClass:'photo-modal-class',
      componentProps: {
        imgs: [{url: img}],
        index: 0
      }
    }).then(modal => modal.present());
  }

  getTotalAmount() {
    return {
      totalAmount: this.booking.item.price + (this.booking.payment.extraChargeOnPayment && this.booking.payment.extraChargeOnPayment.charge ? this.booking.payment.extraChargeOnPayment.charge : 0),
      totalGst: this.booking.item.totalGst,
    }
  }

  viewInvoice() {
	const url = this.booking.invoice.url;
	window.open(url, '_blank');
  }

  getBookingItemPrice() {
    return this.booking.item.price - this.booking.item.addOns.totalPrice - (this.booking.item.gst.isExclusive ? this.booking.item.gst.total : 0);
  }

  modalDismiss() {
	  this.modalController.dismiss();
  }

  convert24to12(time) {
    return this.sharedService.convert24to12(time)
  } 

  isPartialAvailable() {
    return this.booking.payment.partial && this.booking.payment.partial.cod; 
  }

  isCustomInputAvailable() {
    return this.booking.additionalInfo && this.booking.additionalInfo.customInput && this.booking.additionalInfo.customInput.value;
  }
}