import { Component, OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { AdminSettingsService } from 'src/app/services/admin-settings/admin-settings.service';
import { StripePage } from '../payment-ui/stripe/stripe.page';
import { OrderService } from 'src/app/services/order/order.service';
import { PaypalPage } from '../payment-ui/paypal/paypal.page';
import { SharedService } from 'src/app/services/shared/shared.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UpiManualPaymentPage } from '../upi-manual-payment/upi-manual-payment.page';
import { CustomPaymentOptionPage } from '../custom-payment-option/custom-payment-option.page';
import { Storage } from '@ionic/storage';

@Component({
    selector: 'app-payment-module',
    templateUrl: './payment-module.page.html',
    styleUrls: ['./payment-module.page.scss'],
})
export class PaymentModulePage implements OnInit {
    loading: any;
    paytmActive: boolean = false;
    razorpayActive: boolean = false;
    razorpayId: any;
    showLoader: boolean = true;
    isCod: boolean = false;
    walletBalance: any;
    walletActive: boolean = true;
    minOrderAmntToUseWallet: number = 0;
    maxWalletAmntPerOrder: number = 1000;
    walletUsed: boolean = false;
    walletAmount: number = 0;
    envPaytmActive: boolean = true;
    currencyCode: any;
    taxType: string;
    SHARED_LABELS: any = {};
    AUTO_CONFIRM_PAYMENT_LABELS: any = {};
    upiManual = {
        active: false,
        upiId: '',
        qrCode: '',
        successRoute: ''
    };
    cashbackAmount = 0;
    cashbackBalance = 0;
    isCodAvailableForCoupon: boolean = true;
    codPercent = 100;
    partialPayment = {
        status: false,
        cod: 0,
        online: {
            amount: 0,
            completed: false
        }
    }
    paymentType = 'full'; // value: full || partial
    customOption = {
        active: false,
        name: '',
        details: '',
        image: {
            url: ''
        },
        textMandatory: false,
        imageMandatory: false,
        successRoute: ''
    }
    stripeData: any = {
        active: false
    };

  paymentGateways = ['paypal', 'cashfree'];
  paypalObj: any = {
    active: false
  };
  cashfreeObj: any = {
    active: false
  };

  extraCharge = {
      cash: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
      razorpay: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
      paytm: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
      stripe: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
      paypal: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
      cashfree: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0}
  }
    isGstApplicable: any;
    paymentData;
    isExternalOrder = false;

    constructor(private events: Events,
        private alertController: AlertController,
        private loadingController: LoadingController,
        private navCtrl: NavController,
        private toastController: ToastController,
        private labelService: LabelService,
        private configService: ConfigService,
        private adminSettingsService: AdminSettingsService,
        private modalController: ModalController,
        private orderService: OrderService,
        private sharedService: SharedService,
        private paymentService: PaymentService,
        private storage: Storage) {
        // this.route.queryParams.subscribe(params => {
        //     if (this.router.getCurrentNavigation().extras.state) {
        //         this.isCodAvailableForCoupon = this.router.getCurrentNavigation().extras.state.isCodAvailableForCoupon;
        //     }
        // });
    }

    ngOnInit() {
        this.envPaytmActive = this.configService.environment.envPaytmActive;
    }
    async ionViewDidEnter() {
        await this.storage.get('isExternalOrder').then((value) => {
            console.log("isExternalOrder value", value);
            if (value) {
                this.isExternalOrder = value || false;
            }
        });

        this.events.publish('admin-settings:getPaytmData');
        this.events.publish('admin-settings:getRazorPayData');
        this.events.publish('admin-settings:getPaymentInfoData');
        if (!this.isExternalOrder) {
            this.events.publish('wallet:getUserWalletDetails', this.paymentData.order.userId);
            this.events.publish('wallet:getWalletSettings');
        }
        this.initializeSubscriptions();
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.AUTO_CONFIRM_PAYMENT_LABELS = this.labelService.labels['AUTO_CONFIRM_PAYMENT'];

        this.getStripeDetails();

        this.getGateways();
    }
    ionViewWillLeave() {
        this.removeSubscription();
    }
    async initializeSubscriptions() {
        this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['payment_mode_set_msg']}`);
        });
        this.events.subscribe('admin-settings:publishPaytmData', (data) => {
            if (data) {
                this.paytmActive = data.active;
                this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : this.getInitObjForExtraCharge();
            }
        });
        this.events.subscribe('admin-settings:publishRazorPayData', (data) => {
            if (data && data.active && data.id !== '') {
                this.razorpayActive = data.active;
                this.razorpayId = data.id;
                this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : this.getInitObjForExtraCharge();
            }
            this.showLoader = false;
        });
        this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
            let isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
            let extraNoCodChecks = false;
            // if (isCod) {
            //     for (const pdt of this.orderData.products) {
            //         if ((pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership') || ('isCod' in pdt && !pdt.isCod)) {
            //             extraNoCodChecks = true;
            //             break;
            //         }
            //     }
            // }
            if (extraNoCodChecks || !this.isCodAvailableForCoupon) {
                isCod = false;
            }
            this.isCod = isCod;
            this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual : this.upiManual;
            this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
            this.partialPayment.status = this.codPercent < 100 ? true : false;
            this.customOption = 'custom' in data ? data.custom : this.customOption;
            this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : this.getInitObjForExtraCharge();
            this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
        });
        this.events.subscribe('order:ac_modeSetToCashSuccess', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
        });
        this.events.subscribe('order:ac_completePaymentWithWalletSuccess', () => {
            this.loading.dismiss();
            this.presentAlert(`${this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']}`);
        });
        this.events.subscribe('wallet:publishUserWalletDetails', (data) => {
            if (data) {
                this.walletBalance = data.wallet ? data.wallet.balance : 0;
                this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
                console.log('walletBalance', this.walletBalance);
            }
        });
        this.events.subscribe('wallet:publishWalletSettings', (data) => {
            console.log('publishWalletSettings', data);
            if (!this.isEmptyObj(data)) {
                this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
                this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
                this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
            }
        });

        for (const paymentGateway of this.paymentGateways) {
          let paymentGatewayData = await this.adminSettingsService.getPaymentGateway(paymentGateway);
          if (paymentGatewayData && paymentGatewayData.active) {
            if (paymentGateway == 'paypal') {
              this.paypalObj = paymentGatewayData;
            }
            if (paymentGateway == 'cashfree') {
              this.cashfreeObj = paymentGatewayData;
            }
          }
        }
    }

    async completePaymentWithWallet() {
        const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
        if(!alertRes) {
            return;
        }

        await this.sharedService.presentLoading();
        this.paymentData.order.walletAmount = this.walletAmount;
        this.paymentData.order.cashbackAmount = this.cashbackAmount;
        const paymentRes: any = await this.paymentService.walletMethod(this.paymentData);
		paymentRes.response.extraChargeOnPayment = {charge: 0, name:'', gst: 0};
        this.sharedService.dismissLoading();
        this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
        // this.events.publish('order:ac_completePaymentWithWallet', this.orderData);
    }

    async onClickPaymentMode(mode: string, method = '') {
        const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
        if(!alertRes) {
            return;
        }

        this.checkPartialPayment();

        this.paymentData.order.walletAmount = this.walletAmount;
        this.paymentData.order.cashbackAmount = this.cashbackAmount;
        let paymentRes: any = {};
		let extraChargeOnPayment = {charge: 0, name:'', gst: 0};
        if(mode in this.extraCharge) {
            extraChargeOnPayment = this.getExtraChargeAmount(this.extraCharge[mode]);
        }

        await this.sharedService.presentLoading();
        switch(mode) {
            case 'cash':
                paymentRes = await this.paymentService.cashMethod(this.paymentData);
                break;
            case 'razorpay':
                this.paymentData = {...this.paymentData, paymentCreds: {key: this.razorpayId} };
                this.paymentData.method = method;
                paymentRes = await this.paymentService.razorpayMethod(this.paymentData);
                break;
            case 'paytm':
                paymentRes = await this.paymentService.paytmMethod(this.paymentData);
                break;
            case 'upiManual':
                this.sharedService.dismissLoading();
                this.upiManual.successRoute = this.paymentData.successRoute;
                const paymentImgRes: any = await this.openUpiManualModal(this.upiManual);
                await this.sharedService.presentLoading();
                paymentRes = await this.paymentService.upiManaulMethod(this.paymentData, paymentImgRes.paymentImg);
                break;
            case 'custom':
                this.sharedService.dismissLoading();
                this.customOption.successRoute = this.paymentData.successRoute;
                const customOptionResponse: any = await this.openCustomOptionModal(this.customOption);
                await this.sharedService.presentLoading();
                paymentRes = await this.paymentService.customOptionMethod(this.paymentData, customOptionResponse.response);
                break;
            case 'stripe':
                // this.presentStripeModal();
                break;
            case 'paypal':
                // this.presentPaypalModal()
                break;
            case 'cashfree':
                // this.payWithCashfree();
                break;
        }
        paymentRes.response.extraChargeOnPayment = extraChargeOnPayment;
		paymentRes.response.walletAmount = this.walletAmount;
        paymentRes.response.cashbackAmount = this.cashbackAmount;
        this.sharedService.dismissLoading();
        this.paymentService.handlePaymentRes(paymentRes, this.paymentData);
    }

    getExtraChargeAmount(extraChargeObj) {
        let extraCharge = 0;
        extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
        let gst = 0;
        if(Object.keys('extraChargeObj').length && extraChargeObj.charge) {
            if(extraChargeObj.type === 'flat') {
                extraCharge = extraChargeObj.charge;
            } else {
                extraCharge = (this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount)) * (extraChargeObj.charge / 100);
                if(extraCharge > extraChargeObj.maxCharge) {
                    extraCharge = extraChargeObj.maxCharge;
                }
            }
            this.paymentData.order.amount += extraCharge;
            gst = this.calcGst(extraCharge);
        }
        return {charge: extraCharge, name: extraChargeObj.chargeName || '', gst};
    }

    calcGst(extraCharge) {
        return (extraCharge - (extraCharge / (1 + (this.paymentData.order.gst.value / 100))));
        // this.orderData.defaultGst += gstOnExtraCharge;
    }

    // calcGst(extraCharge) {
    //     let allGst = [];
    //     let gstOnExtraCharge = 0;
    //     this.orderData.products.map((p) => {
    //         if (p.gst && this.isGstApplicable) {
    //             allGst.push(p.gst);}
    //     });
    //     if (allGst.length) {
    //         let minGst = 0;
    //         minGst = Math.min(...allGst);
    //         gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
    //         this.orderData.defaultGst += gstOnExtraCharge;
    //         this.orderData.deliveryGstObj.extraChargeGst = (this.orderData.deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
    //     }
    // }

    async payWithCashfree(){
        await this.orderService.createOrderCashfree(this.paymentData, true);
    }

    checkPartialPayment() {
        const partial = { ...this.partialPayment };
        if (this.paymentType === 'partial') {
            partial.status = true;
        } else {
            partial.status = false;
        }
        this.paymentData.order.partialPayment = partial;
    }


    isEmptyObj(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    onClickUseWallet() {
        if (this.walletUsed) {
            this.walletUsed = false;
            this.walletAmount = 0;
            this.cashbackAmount = 0;
        } else {
            this.getUsableWalletAmnt();
            this.walletUsed = true;
        }
    }

    getUsableWalletAmnt() {
        let walletUsed = 0;
        let cashbackUsed = 0;
        if (this.paymentData.order.amount < this.minOrderAmntToUseWallet) {
            walletUsed = this.paymentData.order.amount >= this.walletBalance ? this.walletBalance : this.paymentData.order.amount;
            this.walletAmount = walletUsed;
        } else {
            cashbackUsed = this.paymentData.order.amount >= this.cashbackBalance ? this.cashbackBalance : this.paymentData.order.amount;
            cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
            this.cashbackAmount = cashbackUsed;
            if (this.paymentData.order.amount - this.cashbackAmount > 0) {
                walletUsed = (this.paymentData.order.amount - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.paymentData.order.amount - this.cashbackAmount);
                this.walletAmount = walletUsed;
            }
        }
    }

    setPaymentType(type: string) {
        this.paymentType = type;

        if (type === 'partial') {
            this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
            this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
        }
    }

    getFinalAmount() {
        return this.paymentData.order.amount - (this.walletAmount + this.cashbackAmount);
    }

    async presentToast(msg: string) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
        });
        toast.present();
    }
    async presentAlert(msg: string, page: string = 'tabs/tabs/user-order-history') {
        const alert = await this.alertController.create({
            message: msg,
            backdropDismiss: false,
            buttons: [{
                text: this.SHARED_LABELS['ok'],
                handler: () => {
                    this.navCtrl.navigateRoot([page]);
                }
            }]
        });
        await alert.present();
    }
    async presentLoading() {
        this.loading = await this.loadingController.create({
            message: this.SHARED_LABELS['please_wait'],
        });
        await this.loading.present();
    }

    async getStripeDetails() {
        this.stripeData = await this.adminSettingsService.getStripeData();
        this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
    }

    async presentStripeModal() {
        const modal = await this.modalController.create({
            component: StripePage,
            componentProps: {
              orderData: this.paymentData,
              stripeData: this.stripeData,
              autoConfirm: true
            }
        });

        await modal.present();
    }

    async presentPaypalModal() {
        const modal = await this.modalController.create({
            component: PaypalPage,
            cssClass:'custom-modal',
            componentProps: {
              orderData: this.paymentData,
              autoConfirm: true,
              paypalObj: this.paypalObj
            }
        });

        await modal.present();
    }

    async getGateways() {
      for (const paymentGateway of this.paymentGateways) {
        let paymentGatewayData = await this.adminSettingsService.getPaymentGateway(paymentGateway);
        if (paymentGatewayData && paymentGatewayData.active) {
          if (paymentGateway == 'paypal') {
            this.paypalObj = paymentGatewayData;
            this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
          }
          if (paymentGateway == 'cashfree') {
            this.cashfreeObj = paymentGatewayData;
            this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
          }
        }
      }
    }

    getInitObjForExtraCharge() {
        return {charge: 0, type: 'flat', chargeName: '', maxCharge: 0};
    }

    async openUpiManualModal(upiManual) {
        return new Promise(async (resolve, reject) => {
            const modal = await this.modalController.create({
                component: UpiManualPaymentPage,
                backdropDismiss: false,
                componentProps: { upiManual, pageType: 'modal' }
            });
            modal.onDidDismiss().then((res) => {
                if(res && res.data && !res.data.closed && res.data.paymentImg) {
                    resolve({paymentImg: res.data.paymentImg});
                } else {
                    resolve({paymentImg: ''});
                }
            });
            await modal.present();
        });
    }

    async openCustomOptionModal(customOption) {
        return new Promise(async (resolve, reject) => {
            const modal = await this.modalController.create({
                component: CustomPaymentOptionPage,
                backdropDismiss: false,
                componentProps: { customOption, pageType: 'modal' }
            });
            modal.onDidDismiss().then((res) => {
                if(res && res.data && !res.data.closed && res.data.response) {
                    resolve({response: res.data.response});
                } else {
                    resolve({response: null});
                }
            });
            await modal.present();
        })
    }

    closeModal() {
        this.modalController.dismiss();
		if(this.paymentData.successRoute) {
            this.navCtrl.navigateRoot([this.paymentData.successRoute]);
        }
    }

    removeSubscription() {
        this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
        this.events.unsubscribe('admin-settings:publishPaytmData');
        this.events.unsubscribe('admin-settings:publishRazorPayData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('order:ac_modeSetToCashSuccess');
        this.events.unsubscribe('wallet:publishUserWalletDetails');
        this.events.unsubscribe('wallet:publishWalletSettings');
        this.events.unsubscribe('order:ac_completePaymentWithWalletSuccess');
    }

}