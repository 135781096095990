import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
var MapPage = /** @class */ (function () {
    function MapPage(modalController, deliveryService, loadingController, labelService) {
        this.modalController = modalController;
        this.deliveryService = deliveryService;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.SHARED_LABELS = {};
        // By default google headquarters
        this.lat = 37.419857;
        this.lng = -122.078827;
    }
    MapPage.prototype.ngOnInit = function () {
        var _this = this;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log('position:', position);
            _this.lat = position.coords.latitude;
            _this.lng = position.coords.longitude;
            setTimeout(function () {
                _this.loadMap();
            }, 500);
        }, function (error) {
            setTimeout(function () {
                _this.loadMap();
            }, 500);
            console.log('error:', error);
        });
    };
    MapPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    MapPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: 10000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MapPage.prototype.loadMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLng, mapOptions, marker, content, infoWindow;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('lat:', this.lat, 'lng:', this.lng);
                        return [4 /*yield*/, new google.maps.LatLng(this.lat, this.lng)];
                    case 1:
                        latLng = _a.sent();
                        console.log('latLng obj:', latLng);
                        mapOptions = {
                            center: latLng,
                            zoom: 15,
                            scrollwheel: false,
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        };
                        this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
                        marker = new google.maps.Marker({
                            map: this.map,
                            animation: google.maps.Animation.DROP,
                            draggable: true,
                            position: this.map.getCenter()
                        });
                        this.marker = marker;
                        content = "<p><b>Order will be delivered hare</b></p><p>Place the pin accurately on the map</p>";
                        infoWindow = new google.maps.InfoWindow({
                            content: content
                        });
                        infoWindow.open(this.map, marker);
                        new google.maps.event.addListener(this.marker, 'dragend', function (event) {
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MapPage.prototype.saveAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLngObj, address, formatted_address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.presentLoading();
                        latLngObj = {
                            lat: this.marker.getPosition().lat(),
                            lng: this.marker.getPosition().lng()
                        };
                        return [4 /*yield*/, this.deliveryService.getAddressFromLatLng(latLngObj)];
                    case 1:
                        address = _a.sent();
                        console.log("address:", address);
                        formatted_address = address.results[0].formatted_address;
                        this.loading.dismiss();
                        this.modalController.dismiss({ address: formatted_address, location: { lat: latLngObj.lat, lng: latLngObj.lng } });
                        return [2 /*return*/];
                }
            });
        });
    };
    return MapPage;
}());
export { MapPage };
