import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import * as firebase from 'firebase';
import { firestore } from 'firebase';
import { UserService } from '../user/user.service';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../user/user.service";
import * as i4 from "@ionic/storage";
var CouponCodesService = /** @class */ (function () {
    function CouponCodesService(events, afs, userService, storage) {
        this.events = events;
        this.afs = afs;
        this.userService = userService;
        this.storage = storage;
    }
    CouponCodesService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('coupon-codes:getAllCouponCodes', function () {
            _this.getAllCouponCodes();
        });
        this.events.subscribe('coupon-codes:addNewCouponCode', function (data) {
            _this.addNewCouponCode(data);
        });
        this.events.subscribe('coupon-codes:editCouponCode', function (id, data) {
            _this.editCouponCode(id, data);
        });
        this.events.subscribe('coupon-codes:deleteCouponCode', function (id) {
            _this.deleteCouponCode(id);
        });
        this.events.subscribe('coupon-codes:deleteEditCouponCode', function (id) {
            _this.deleteEditCouponCode(id);
        });
        this.events.subscribe('coupon-codes:addProductAsNotApplicable', function (product, codeId) {
            _this.addProductAsNotApplicable(product, codeId);
        });
        this.events.subscribe('coupon-codes:removeNotApplicableProduct', function (product, codeId) {
            _this.removeNotApplicableProduct(product, codeId);
        });
        this.events.subscribe('coupon-codes:verifyCouponCode', function (data) {
            _this.verifyCouponCode(data);
        });
        this.events.subscribe('coupon-codes:removeSubs', function () {
            if (_this.getCouponsSub) {
                _this.getCouponsSub.unsubscribe();
            }
        });
    };
    CouponCodesService.prototype.getAllCouponCodes = function () {
        var _this = this;
        try {
            var couponsRef = this.afs.collection('features').doc('coupons').collection('codes', function (ref) { return ref
                .orderBy('createdAt', 'desc'); });
            var couponsSnap = couponsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return tslib_1.__assign({ id: id }, data);
            }); }));
            this.getCouponsSub = couponsSnap.subscribe(function (res) {
                if (!res.length) {
                    _this.events.publish('coupon-codes:noCouponCodes');
                }
                else {
                    _this.events.publish('coupon-codes:publishAllCouponCodes', res);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    CouponCodesService.prototype.addNewCouponCode = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var couponCode, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes', function (ref) { return ref
                                .where('name', '==', data.name); }).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        couponCode = _a.sent();
                        if (!(couponCode && couponCode.length > 0)) return [3 /*break*/, 2];
                        this.events.publish('coupon-codes:couponCodeAlreadyExists', data.name);
                        return [3 /*break*/, 4];
                    case 2:
                        data["createdAt"] = firebase.firestore.FieldValue.serverTimestamp();
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').add(data)];
                    case 3:
                        _a.sent();
                        this.events.publish('coupon-codes:addNewCouponCodeSuccess');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.editCouponCode = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').doc(id).update(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('coupon-codes:editCouponCodeSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.deleteCouponCode = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('coupon-codes:deleteCouponCodeSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.deleteEditCouponCode = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('coupon-codes:deleteEditCouponCodeSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.addProductAsNotApplicable = function (product, codeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').doc(codeId).update({
                                notApplicableProducts: firestore.FieldValue.arrayUnion(product)
                            })];
                    case 1:
                        _a.sent();
                        this.events.publish('coupon-codes:addProductAsNotApplicableSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.removeNotApplicableProduct = function (product, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes').doc(id).update({
                                notApplicableProducts: product
                            })];
                    case 1:
                        _a.sent();
                        this.events.publish('coupon-codes:removeNotApplicableProductSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.verifyCouponCode = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, verifyCouponCode, error_7;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _a = data;
                        _b = 'userId';
                        _c = this.userService.getUserId();
                        if (_c) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        _c = (_d.sent());
                        _d.label = 2;
                    case 2:
                        _a[_b] = _c;
                        ;
                        verifyCouponCode = firebase.functions().httpsCallable('orders-verifyCouponCode');
                        verifyCouponCode(data).then(function (response) {
                            //// console.log('res for cc', response);
                            if (response && response.data) {
                                if (response.data.success === false) {
                                    _this.events.publish('coupon-codes:couponCodeNotApplied', response.data.failureMsg);
                                }
                                else {
                                    _this.events.publish('coupon-codes:couponCodeApplied', response.data);
                                }
                            }
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _d.sent();
                        console.dir(error_7);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.getCouponCodesForUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var couponData, uid_1, allCoupons, availableCoupons, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        couponData = (_a.sent()) || { showAllCoupons: true };
                        if (Object.keys(couponData).length && !couponData.showAllCoupons) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        uid_1 = _a.sent();
                        return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); }).valueChanges().pipe(first()).toPromise()];
                    case 3:
                        allCoupons = _a.sent();
                        availableCoupons = allCoupons.filter(function (coupon) {
                            if (moment().isSameOrBefore(moment(coupon.validUpto), 'day') && (coupon.qty - coupon.usage >= 1)) {
                                if (coupon.specificUsers.isAllowed) {
                                    if (coupon.specificUsers.users.some(function (u) { return u.id === uid_1; })) {
                                        return coupon;
                                    }
                                }
                                else {
                                    return coupon;
                                }
                            }
                        });
                        console.log('availableCoupons', availableCoupons);
                        return [2 /*return*/, availableCoupons];
                    case 4:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CouponCodesService.prototype.checkCODAvailabilityOfCoupon = function (couponId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var coupon, error_9;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('features').doc('coupons').collection('codes', function (ref) { return ref
                                            .where(firebase.firestore.FieldPath.documentId(), 'in', [couponId]); })
                                            .valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    coupon = _a.sent();
                                    if (coupon && coupon.length) {
                                        resolve('codAvailable' in coupon[0] ? coupon[0].codAvailable : true);
                                    }
                                    else {
                                        resolve(true);
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_9 = _a.sent();
                                    console.log(error_9);
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CouponCodesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CouponCodesService_Factory() { return new CouponCodesService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.Storage)); }, token: CouponCodesService, providedIn: "root" });
    return CouponCodesService;
}());
export { CouponCodesService };
